<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card elevation="0">
        <v-row no-gutters>
          <v-col cols="12" md="6" sm="6" class="pl-2">
            <v-card-title class="secondary--text text-capitalize">
              {{ message.title }}
            </v-card-title>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-card-text class="mt-2">
              <div class="text-caption">
                Created at:
                {{ $date(message.created_at).format('DD-MM-YYYY HH:mm') }}
              </div>
              <div v-if="message.last_published_at" class="text-caption">
                Last Published at:
                {{
                  $date(message.last_published_at).format('DD-MM-YYYY HH:mm')
                }}
              </div>
            </v-card-text>
          </v-col>
        </v-row>

        <v-card-text class="accent--text text-h5">Message</v-card-text>

        <v-card-text class="message" v-html="message.description"></v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text rounded @click="closeDialog">
            {{ $t('closeButtonText') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style scoped>
.message /deep/ img {
  height: auto;
  max-width: 100%;
}
</style>
