<template>
  <div>
    <v-toolbar color="#f8f8f8" dense>
      <v-toolbar-title class="secondary--text text-subtitle-2 text-capitalize">
        {{ $t('dashboard') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-navigation-drawer v-model="filterDrawer" app right width="320">
      <v-form class="pa-4">
        <h6 class="text-h6 text-capitalize">
          {{ $t('companiesFilterText') }}
        </h6>
        <v-row no-gutters>
          <v-col>
            <p class="text-subtitle-2 text-capitalize">{{ $t('company') }}</p>
            <v-autocomplete
              v-model="filterCompany"
              :items="filterCompanies"
              :label="$t('companyNameTranslate')"
              dense
              outlined
              rounded
              clearable
              open-on-clear
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              class="text-capitalize"
              text
              rounded
              @click="closeFilterSidebar"
            >
              {{ $t('cancelButtonText') }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              class="text-capitalize"
              color="accent"
              rounded
              @click="clear"
            >
              {{ $t('clearButtonText') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-navigation-drawer>
    <v-toolbar light elevation="1" dense>
      <v-toolbar-title class="text-subtitle-2 text-capitalize">
        {{ $t('companies') }}: {{ companiesCount }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <p class="mb-0 pr-2 text-caption text-capitalize">
        {{ $t('recordsTranslate') }}:
      </p>
      <v-responsive max-width="85">
        <v-select
          v-model="itemPerPage"
          item-text="text"
          item-value="value"
          :items="itemsPerPage"
          dense
          outlined
          hide-details
          solo
          flat
          return-object
        ></v-select>
      </v-responsive>

      <v-btn icon @click.stop="filterDrawer = !filterDrawer">
        <v-icon>mdi-filter-variant</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container fluid class="pl-0 pr-0 pt-1 pb-0">
      <v-card flat class="pa-6">
        <v-row>
          <v-col class="col-md-8 pt-1 pb-0 pr-0">
            <v-card height="695px" class="my-list" :loading="loading">
              <template slot="progress">
                <LoadingBar></LoadingBar>
              </template>

              <v-row>
                <v-col
                  v-for="(company, index) in showByCountCompaniesList"
                  :key="index"
                  cols="12"
                  sm="6"
                  md="4"
                  class="pl-0 pr-1 pb-0"
                >
                  <v-card
                    :to="{
                      path: '/bonus/tracker',
                      query: { name: company.name },
                    }"
                    class="pa-4 text-center pr-0 pl-0"
                    color="#f5f3f3"
                    height="160px"
                    elevation="2"
                    min-width="180px"
                    outlined
                  >
                    <v-card-text>
                      <p class="blue-text font-weight-bold text-h6">
                        {{ company.employee_count }} /
                        {{ company.total_emp_count }}
                      </p>
                      <h3 class="accent--text font-weight-bold">
                        {{ company.name }}
                      </h3>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- Announcement messages section  -->
          <v-col class="col-md-4 pl-0 ml-0 pt-1 pb-0">
            <template>
              <v-card
                class="mx-auto mr-0 ml-3"
                height="695px"
                min-width="270px"
                :loading="loading"
              >
                <template slot="progress">
                  <v-skeleton-loader type="text@3, text@3"></v-skeleton-loader>
                </template>

                <v-toolbar v-show="loading === false" color="accent" dense>
                  <v-toolbar-title
                    elevation="2"
                    class="text-subtitle-3 text-capitalize white-text"
                  >
                    {{ $t('announcements') }}
                  </v-toolbar-title>
                </v-toolbar>

                <v-row v-if="allMessages.length > 0" no-gutters>
                  <v-col
                    v-for="item in allMessages"
                    v-show="loading === false"
                    :key="item.id"
                    cols="12"
                    sm="12"
                  >
                    <v-list-item nav class="pl-0 pr-0">
                      <v-list-item @click="openMessageViewer(item)">
                        <h5 class="subtitle-2">{{ item.title }}</h5>

                        <v-spacer></v-spacer>

                        <h5 class="accent--text text-caption">
                          {{ dateFormater(item.created_at) }}
                        </h5>
                      </v-list-item>

                      <v-list-item-action>
                        <v-icon
                          size="20"
                          @click="openDeleteMessageDialog(item)"
                        >
                          mdi-close
                        </v-icon>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider :inset="item.inset"></v-divider>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col>
                    <v-card-text class="accent--text text-h6 text-lg-center">
                      <v-icon size="40"> &#x1F913; </v-icon>
                    </v-card-text>
                    <v-card-text class="accent--text subtitle-1 text-lg-center"
                      >No announcements
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </template>
          </v-col>
        </v-row>
      </v-card>
      <DeleteItemDialog
        :dialog="showDeleteMessageDialog"
        :item="itemToDeleteMessage"
        @close-dialog="closeDeleteMessageDialog"
        @delete-item="deleteMessage"
      ></DeleteItemDialog>

      <SettingsViewMessageDialog
        :dialog="viewMessageDialog"
        :message="message"
        @close-dialog="closeViewMessageDialog"
      ></SettingsViewMessageDialog>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDistance, parseISO } from 'date-fns';
import SettingsViewMessageDialog from '@/components/SettingsViewMessageDialog.vue';
import DeleteItemDialog from '@/components/DeleteItemDialog';
import LoadingBar from '@/components/LoadingBar.vue';

export default {
  name: 'HrDashboard',

  components: {
    DeleteItemDialog,
    SettingsViewMessageDialog,
    LoadingBar,
  },
  data: () => ({
    drawer: null,
    viewMessageDialog: false,
    message: {},
    showDeleteMessageDialog: false,
    itemToDeleteId: -1,
    itemToDeleteMessage: '',

    filterDrawer: false,
    companies: [],
    loading: false,
    errored: false,
    filterCompany: '',

    itemPerPage: {
      text: '25',
      value: 25,
    },

    itemsPerPage: [
      {
        text: '25',
        value: 25,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'All',
        value: -1,
      },
    ],
  }),

  computed: {
    ...mapGetters('welcome_messages', {
      allMessages: 'allMessages',
    }),

    companiesCount() {
      return this.companies.length;
    },
    filterCompanies() {
      return this.companies.map((x) => x.name);
    },
    user() {
      return this.$store.state.currentUser;
    },
    fullName() {
      return (
        this.$store.state.currentUser.first_name +
        ' ' +
        this.$store.state.currentUser.last_name
      );
    },
    initials() {
      return (
        this.$store.state.currentUser.first_name[0] +
        this.$store.state.currentUser.first_name[0]
      );
    },

    // filter companies
    filteredCompaniesList() {
      let conditions = [];

      if (this.filterCompany) {
        conditions.push(this.filterByCompany);
      }

      if (conditions.length > 0) {
        return this.companies.filter((company) => {
          return conditions.every((condition) => {
            return condition(company);
          });
        });
      }

      return this.companies;
    },

    showByCountCompaniesList() {
      return this.filteredCompaniesList.slice(0, this.itemPerPage.value);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    itemPerPage(val) {
      this.showByCountCompaniesList = this.filteredCompaniesList.slice(
        0,
        val.value
      );
    },
  },

  beforeMount() {
    // this.$store.dispatch('welcome_messages/loadMessages');
    this.loadMessages();
    // this.$store.dispatch('welcome_messages/loadActiveMessage');
  },

  created() {
    this.loading = true;
    this.companies = [];

    this.$http
      .get('bms/api/companies/employee/count/')
      .then((res) => {
        this.companies = res.data.sort((a, b) => {
          return b.employee_count - a.employee_count;
        });
      })
      .catch((err) => {
        this.errored = true;
        throw err;
      })
      .finally(() => (this.loading = false));
  },

  methods: {
    ...mapActions('welcome_messages', {
      loadMessages: 'loadMessages',
      removeMessage: 'delMessage',
    }),

    // TODO: discuss on message items delete flow
    deleteMessage() {
      // emit action to delete selected message
      // this.$emit('delete-message', this.itemToDeleteId);

      // this delete works fine
      let feedback = {
        status: 'submitting',
        message: this.$t('deleting'),
      };

      this.$emit('show-feedback', feedback);

      this.removeMessage(this.itemToDeleteId)
        .then(() => {
          this.$emit('show-feedback', {
            status: 'success',
            message: this.$t('deletedSuccessfully'),
          });
        })
        .catch(() => {
          this.$emit('show-feedback', {
            status: 'fail',
            message: this.$t('deletingFailed'),
          });
        });

      // close delete dialog
      this.closeDeleteMessageDialog();
    },

    openDeleteMessageDialog(item) {
      // set item to delete id
      this.itemToDeleteId = item.id;
      this.itemToDeleteMessage = item.title;
      // launch delete item dialog
      this.showDeleteMessageDialog = true;
      // this.deleteMessage();
    },

    closeDeleteMessageDialog() {
      this.showDeleteMessageDialog = false;
      this.$nextTick(() => {
        this.itemToDelete = -1;
        this.itemToDeleteMessage = '';
      });
    },

    openMessageViewer(item) {
      this.message = item;
      this.viewMessageDialog = true;
    },

    closeMessageViewer() {},

    closeViewMessageDialog() {
      this.viewMessageDialog = false;
      this.$nextTick(() => {
        this.message = Object.assign(this.message, {});
      });
    },

    dateFormater(dateVal) {
      return formatDistance(parseISO(dateVal), new Date(), {
        addSuffix: true,
      });
    },

    filterByCompany(company) {
      return company.name
        .toLowerCase()
        .includes(this.filterCompany.toLowerCase());
    },

    closeFilterSidebar() {
      // toggle filter sidebar;
      this.filterDrawer = false;
    },

    // clear filters
    clear() {
      this.filterCompany = '';
    },
  },
};
</script>

<style lang="scss">
.blue-text {
  color: blue;
}
.white-text {
  color: white;
}
p.accent--text {
  font-size: 13px;
}
.my-list {
  height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}
.v-application .pa-6 {
  padding: 10px !important;
}
</style>
